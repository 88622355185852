<template>
    <vx-card class="overflow-hidden">
        <div class="flex justify-between flex-row-reverse items-center">
            <feather-icon
                :icon="'CodeIcon'"
                class="p-3 inline-flex rounded-full"
                :class="[`text-primary`, { 'mb-4': true }]"
                :style="{
                    background: `rgba(var(--vs-primary),.15)`,
                }"
            ></feather-icon>

            <div class="truncate">
                <h2 class="mb-1 font-bold">Access Codes</h2>
                <!--<small style="font-size: 1.2rem"></small>-->
            </div>
        </div>
        <div class="p-3 pb-4">
            <div class="vx-row font-bold pb-2">
                <div class="vx-col w-1/4">Code</div>
                <div class="vx-col w-1/2">Description</div>
                <div class="vx-col w-1/4 text-right">Used</div>
            </div>
            <div
                v-for="(code, index) in quickCodesFiltered"
                :key="index"
                class="vx-row"
            >
                <div class="vx-col w-1/4">{{ code.code }}</div>
                <div class="vx-col w-1/2">{{ code.description }}</div>
                <div class="vx-col w-1/4 text-right">{{ code.used_count }}</div>
            </div>
        </div>

        <div class="flex flex-row-reverse w-full">
            <vs-button type="flat" v-on:click="gotoCodes" class="w-full">{{
                buttonText
            }}</vs-button>
        </div>
    </vx-card>
</template>
<script>
export default {
    name: 'QuickCodeCard',
    computed: {
        quickCodes() {
            return this.$store.getters.quickCodesOverview
        },
        quickCodesFiltered() {
            if (this.quickCodes && this.quickCodes.data) {
                var copyData = []
                Object.assign(copyData, this.quickCodes.data)
                return copyData.sort((a, b) => b.used_count - a.used_count)
            }
            return null
        },
        buttonText() {
            if (this.quickCodes && this.quickCodes.data) {
                if (this.quickCodes.count <= 3) return 'View'
                else {
                    const others =
                        this.quickCodes.count - this.quickCodes.data.length
                    console.log(others)
                    return others > 1
                        ? `${others} More Codes`
                        : `${others} More Code`
                }
            }
            return 'Start'
        },
    },
    methods: {
        gotoCodes() {
            this.$router
                .push({ name: 'quickCodes' })
                .catch((e) => console.log(e))
        },
    },
}
</script>
