<template>
    <vx-card
        actionable
        title-color="#fff"
        subtitle-color="#fff"
        content-color="#fff"
        card-background="rgba(var(--vs-success),0.65)"
        class="overflow-hidden p-6"
    >
        <router-link :to="to">
            <div class="items-center text-center">
                <feather-icon
                    :icon="'FilePlusIcon'"
                    class="p-3 inline-flex rounded-full"
                    :class="[`text-white`, { 'mb-4': true }]"
                    :style="{
                        background: `rgba(var(--vs-success),.15)`,
                    }"
                ></feather-icon>

                <div class="truncate text-white">
                    <h2 class="mb-1 font-bold text-white">Start</h2>
                    <small style="font-size: 1.2rem">Client Will</small>
                </div>
            </div>
        </router-link>
    </vx-card>
</template>

<script>
export default {
    name: 'StartWillCard',
    data() {
        return {
            to: 'handover',
        }
    },
    methods: {},
}
</script>
