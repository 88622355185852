<template>
    <div>
        <div class="vx-row">
            <div class="vx-col w-full md:w-1/2 lg:w-1/2">
                <vx-card
                    class="h-full bg-transparent no-shadow mb-base"
                    v-if="broker"
                >
                    <h2 class="mb-3">Broker <strong>Management</strong></h2>
                </vx-card>
            </div>
        </div>

        <div class="vx-row">
            <div class="vx-col w-full mb-base">
                <div class="vx-row">
                    <div class="vx-col w-full sm:w-1/2 md:w-1/4 mb-base">
                        <SendSingleCard />
                    </div>
                    <div class="vx-col w-full sm:w-1/2 md:w-1/4 mb-base">
                        <SendBatchCard />
                    </div>
                    <div class="vx-col w-full sm:w-1/2 md:w-1/4 mb-base">
                        <StartWillCard />
                    </div>
                    <div class="vx-col w-full sm:w-1/2 md:w-1/4 mb-base">
                        <ReferClientCard />
                    </div>
                </div>
            </div>

            <div class="vx-col w-full mb-base">
                <div class="vx-row">
                    <div
                        class="vx-col w-full md:w-1/2 lg:w-1/2 xl:w-1/3 mb-base"
                    >
                        <LeadsCard />
                    </div>
                    <div
                        class="vx-col w-full md:w-1/2 lg:w-1/2 xl:w-1/3 mb-base"
                    >
                        <HandoverCard />
                    </div>
                    <div
                        class="vx-col w-full md:w-1/2 lg:w-1/2 xl:w-1/3 mb-base"
                    >
                        <QuickCodeCard />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QuickCodeCard from '@/views/main/Home/Cards/QuickCodes'
import HandoverCard from '@/views/main/Home/Cards/Handover'
import SendSingleCard from '@/views/main/Home/Cards/SendSingle'
import SendBatchCard from '@/views/main/Home/Cards/SendBatch'
import StartWillCard from '@/views/main/Home/Cards/StartWill'
import ReferClientCard from '@/views/main/Home/Cards/ReferClient'
import LeadsCard from '@/views/main/Home/Cards/Leads'

export default {
    name: 'Home',
    components: {
        LeadsCard,
        ReferClientCard,
        StartWillCard,
        SendBatchCard,
        QuickCodeCard,
        HandoverCard,
        SendSingleCard,
    },
    computed: {
        broker() {
            if (this.$store.getters.user)
                return this.$store.getters.user.broker_permission
            return null
        },
    },
    data() {
        return {}
    },
    mounted() {},
    methods: {
        chat() {
            document.getElementById('chat-head').click()
        },
    },
}
</script>
