<template>
    <vx-card class="overflow-hidden">
        <div class="flex justify-between flex-row-reverse items-center">
            <feather-icon
                :badge="handoverAccounts.count"
                :icon="'FilePlusIcon'"
                class="p-3 inline-flex rounded-full"
                :class="[`text-primary`, { 'mb-4': true }]"
                :style="{
                    background: `rgba(var(--vs-primary),.15)`,
                }"
            ></feather-icon>

            <div class="truncate">
                <h2 class="mb-1 font-bold">Wills Started</h2>
                <!--<small style="font-size: 1.2rem"></small>-->
            </div>
        </div>
        <div class="p-3 pb-4" v-if="handoverAccounts">
            <div class="vx-row font-bold pb-2">
                <div class="vx-col w-full">Recent</div>
            </div>
            <div
                v-for="(account, index) in handoverAccounts.data"
                :key="index"
                class="vx-row"
            >
                <div
                    class="vx-col w-full"
                    v-if="
                        account.personal_details &&
                        account.personal_details.full_name
                    "
                >
                    {{ account.personal_details.full_name }}
                </div>
                <div v-else class="vx-col w-full">
                    {{ account.email }}
                </div>
            </div>
        </div>

        <div v-else class="font-bold text-center">
            No pending handover account
        </div>

        <div class="flex flex-row-reverse w-full">
            <vs-button
                type="flat"
                v-on:click="gotoAccount"
                class="w-full"
                color="primary"
                >{{ buttonText }}</vs-button
            >
        </div>
    </vx-card>
</template>
<script>
export default {
    name: 'HandoverCard',
    computed: {
        handoverAccounts() {
            return this.$store.getters.handoverAccountsOverview
        },
        buttonText() {
            if (this.handoverAccounts && this.handoverAccounts.data) {
                if (this.handoverAccounts.count <= 3) return 'View'
                else return 'View More'
            }
            return 'Start'
        },
    },
    methods: {
        gotoAccount() {
            this.$router.push({ name: 'handover' }).catch((e) => console.log(e))
        },
    },
}
</script>
