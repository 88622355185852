<template>
    <vx-card class="overflow-hidden">
        <div class="flex justify-between flex-row-reverse items-center">
            <feather-icon
                :badge="leads.count"
                :icon="'MessageSquareIcon'"
                class="p-3 inline-flex rounded-full"
                :class="[`text-primary`, { 'mb-4': true }]"
                :style="{
                    background: `rgba(var(--vs-primary),.15)`,
                }"
            ></feather-icon>

            <div class="truncate">
                <h2 class="mb-1 font-bold">Leads</h2>
            </div>
        </div>
        <div v-if="leadsFiltered && leadsFiltered.length" class="p-3 pb-4">
            <div class="vx-row font-bold pb-2">
                <div class="vx-col w-2/3">Client</div>
                <div class="vx-col w-1/3"></div>
            </div>
            <div
                v-for="(lead, index) in leadsFiltered"
                :key="index"
                class="vx-row"
            >
                <div class="vx-col w-2/3" v-if='lead && lead.user && lead.user.personaldetails && lead.user.personaldetails.full_name'>
                    {{ lead.user.personaldetails.full_name }}
                </div>
                <div class="vx-col w-1/3">
                    {{ formatDate(lead.date_request) }}
                </div>
            </div>
        </div>
        <div v-else>
            <p>No open leads</p>
        </div>

        <div class="flex flex-row-reverse w-full">
            <vs-button
                type="flat"
                v-on:click="gotoCodes"
                class="w-full"
                color="primary"
                >{{ buttonText }}</vs-button
            >
        </div>
    </vx-card>
</template>
<script>
import { textFormat } from '@/mixins/textFormat'

export default {
    name: 'LeadsCard',
    mixins: [textFormat],
    computed: {
        leads() {
            return this.$store.getters.leadsOverview
        },
        leadsFiltered() {
            if (this.leads && this.leads.data) {
                var copyData = []
                Object.assign(copyData, this.leads.data)
                return copyData.sort((a, b) => b.used_count - a.used_count)
            }
            return null
        },
        buttonText() {
            if (this.leads && this.leads.data) {
                if (this.leads.count <= 3) return 'View'
                else return 'View More'
            }
            return 'Start'
        },
    },
    methods: {
        gotoCodes() {
            this.$router.push({ name: 'leads' }).catch((e) => console.log(e))
        },
    },
}
</script>
